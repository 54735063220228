.container {
    background-color: #1f2a40;
    border-radius: 14px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 30px;
    padding: 15px 20px;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.icon {
    transition: all 2s ease;
}

.button {
    background-color: #6770fa;
    color: #fff;
    outline: none;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
}

.button:hover {
    opacity: 0.9;
    cursor: pointer;
}