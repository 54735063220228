:root {

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
}

html {
  font-size: 18px;
}

.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 100vh;
}